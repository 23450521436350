import { useEffect } from 'react'
import { RELEASE_ENV } from 'config'

declare global {
  interface Window {
    dataLayer?: unknown[]
  }
}

export type Params = {
  eventName: string
  properties?: Record<string, string>
}

/**
 * Hook that sends an event to the Google Tag Manager dataLayer.
 * WARNING: Just calling this hook is not enough to make the event passed show
 * up in Google Analytics. Extra setup is required for each type of event you
 * want GTM to forward on to GA. See https://www.notion.so/farewill/Forwarding-events-to-GA4-via-GTM-6b4c8d262e054dacbd3a6d0d1f2486d9?pvs=4
 */
export const useRecordGAPageview = (
  eventName: Params['eventName'],
  properties?: Params['properties']
): void => {
  useEffect(() => {
    const dataLayerEntry = {
      event: eventName,
      ...properties,
    }

    if (RELEASE_ENV !== 'production') {
      if (Array.isArray(window.dataLayer)) {
        // eslint-disable-next-line no-console
        console.debug(
          `[useRecordGAPageview] Sending ${eventName} event to GTM dataLayer`
        )
      } else {
        console.warn(
          `[useRecordGAPageview] Could not send ${eventName} event to GTM dataLayer - dataLayer does not exist on window. Has GTM been configured correctly?`
        )
      }
    }
    window.dataLayer?.push(dataLayerEntry)
  }, [])
}
