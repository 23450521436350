import React from 'react'
import { HelmetProps } from 'react-helmet'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import { TRACKING_TYPES } from 'lib/tracking/constants'
import CoBrandedBanner from 'components/CoBrandedBanner'

type Partner = {
  bannerBackgroundColor: string
  bannerLogoAlignment: 'left' | 'right'
  bannerText?: { bannerText: string }
  bannerTextColor: string
  logo: { file: { url: string }; title: string }
  utmSource: string
  utmChannel: string
  telephoneWillsEnabled?: boolean
  hideFarewillLogoInHeader?: boolean | null
  partnerId: string
}

type PartnerLandingPageLayoutProps = {
  title?: string | null
  description?: string | null
  meta?: HelmetProps['meta']
  product?: PRODUCTS | null
  canonicalPath: string
  utmCampaign: string
  utmMedium?: string | null
  partner: Partner
  termsAndConditions?: { termsAndConditions: string } | null
  children: NonNullable<React.ReactNode>
  bannerText?: string | null
}

const PartnerLandingPageLayout = ({
  title = null,
  description = null,
  meta = [],
  product = null,
  canonicalPath,
  utmCampaign,
  utmMedium = null,
  partner,
  termsAndConditions = null,
  children,
  bannerText = null,
}: PartnerLandingPageLayoutProps): React.ReactElement => {
  const utmParameters = {
    utmCampaign: utmCampaign || TRACKING_TYPES.NOT_SET,
    utmMedium: utmMedium || TRACKING_TYPES.NOT_SET,
    utmSource: partner?.utmSource || TRACKING_TYPES.NOT_SET,
    utmChannel: partner?.utmChannel || TRACKING_TYPES.NOT_SET,
    partnerId: partner?.partnerId || TRACKING_TYPES.NOT_SET,
  }

  return (
    <>
      {partner && (
        <CoBrandedBanner
          text={bannerText || (partner.bannerText?.bannerText as string)}
          textColour={partner.bannerTextColor}
          backgroundColour={partner.bannerBackgroundColor}
          logo={partner.logo}
          rightAlignLogo={partner.bannerLogoAlignment === 'right'}
          termsAndConditions={
            termsAndConditions && termsAndConditions.termsAndConditions
          }
        />
      )}

      <DefaultLayout
        title={title}
        description={description}
        meta={meta}
        product={product}
        canonicalPath={canonicalPath}
        headerColor={COLOR.WHITE}
        headerType="compact"
        footerType="compact"
        utmParameters={utmParameters}
        headerHideFarewillLogo={partner?.hideFarewillLogoInHeader}
        headerHideTelephoneNumber={!partner?.telephoneWillsEnabled}
      >
        {children}
      </DefaultLayout>
    </>
  )
}

export default PartnerLandingPageLayout
