import { useContext, useEffect, useState } from 'react'
import { useTrack, SplitContext } from '@splitsoftware/splitio-react'

export type Params = {
  eventName: string
  value?: number
  properties?: SplitIO.Properties
}

export const useRecordSplitPageview = (
  eventName: Params['eventName'],
  value?: Params['value'],
  properties?: Params['properties']
): void => {
  const { isReady } = useContext(SplitContext)
  const [recorded, setRecorded] = useState(false)
  const track = useTrack()

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (!recorded) {
      track(eventName, value, properties)
      setRecorded(true)
    }
  }, [isReady])
}
