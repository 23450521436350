import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, BackgroundImageWrapper } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { smallCaps } from '@farewill/ui/helpers/text'

import { formatPriceInPounds } from 'lib/formatting/pricing'

const StyledWrapper = styled.div`
  position: relative;
`

const StyledFeeBubble = styled(BackgroundImageWrapper)`
  position: relative;
  z-index: 2;
  width: 170px;
  height: 170px;
  box-sizing: border-box;
  padding: 0 ${GTR.S};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.25;
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.BLACK};
  margin: 0 auto -80px;

  ${screenMin.m`
    width: 210px;
    height: 210px;
    margin: 0 auto -100px;
  `}

  ${screenMin.l`
    margin: 0;
  `}
`

const StyledFeeBubbleTitle = styled.dt`
  ${smallCaps}
  font-size: ${FONT.SIZE.XXS};
`

const StyledFeeBubbleFee = styled.dd`
  margin: 0 0 ${GTR.XXS};
  font-size: ${FONT.SIZE.XXL};
  font-family: ${FONT.FAMILY.DECORATIVE};
  color: ${COLOR.BLACK};
  line-height: 1;

  ${screenMin.m`
    font-size: ${FONT.SIZE.XXXL};
  `}
`

const StyledPricingDetails = styled(Wrapper)`
  background: ${COLOR.WHITE};
  z-index: 1;
  padding: 90px ${GTR.S} ${GTR.S};

  ${screenMin.m`
    padding: 110px ${GTR.M} ${GTR.M};
  `}

  ${screenMin.l`
    position: absolute;
    padding: ${GTR.S} ${GTR.XS} ${GTR.S} 100px;
    top: 50%;
    right: 0;
    left: 60px;
    transform: translate(0, -50%);
  `}

  ${screenMin.l`
    left: 105px;
    padding: ${GTR.M} ${GTR.M} ${GTR.M} 130px;
  `}
`

const ProductPricing = ({ price, priceNote, children }) => (
  <StyledWrapper>
    <StyledFeeBubble
      imagePath="textures/blob-1-yellow"
      imageWidth={170}
      imageWidthFromL={210}
      cover
      tag="dl"
    >
      <StyledFeeBubbleTitle>Our price</StyledFeeBubbleTitle>
      <StyledFeeBubbleFee>{formatPriceInPounds(price)}</StyledFeeBubbleFee>
      {priceNote}
    </StyledFeeBubble>

    <StyledPricingDetails bordered borderRadius="S">
      {children}
    </StyledPricingDetails>
  </StyledWrapper>
)

ProductPricing.propTypes = {
  price: PropTypes.number.isRequired,
  priceNote: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default ProductPricing
