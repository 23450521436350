export const PARTNER_CAMPAIGN_PAGE_TELEPHONE_WILLS_ENABLED_VALUE = 'Yes'
export const PARTNER_CAMPAIGN_PAGE_TELEPHONE_WILLS_DISABLED_VALUE = 'No'
export type TelephoneWillsEnabledCampaignOverride =
  | typeof PARTNER_CAMPAIGN_PAGE_TELEPHONE_WILLS_ENABLED_VALUE
  | typeof PARTNER_CAMPAIGN_PAGE_TELEPHONE_WILLS_DISABLED_VALUE
  | null

/**
 * Helper function that returns a boolean indicating the state of the
 * telephoneWillsEnabled flag, taking into account the setting on the partner
 * level and any override at the campaign level.
 *
 * @remarks
 * This helper function helps with typing. The `telephoneWillsEnabled` field
 * on the `Partner` in Contentful is a boolean, while the
 * `telephoneWillsEnabled` override field on the `Partner Campaign Page` document
 * is a nullable `string` with the possible values of `Yes` or `No`.
 *
 * @param partnerSetting - The value of the telephoneWillsEnabled field on the
 * `Partner` document from Contentful.
 * @param campaignSetting - The value of the telephoneWillsEnabled field on the
 * `Partner Campaign Page` document from Contentful.
 * @returns true if telephone wills should be enabled, otherwise false.
 */
export const getTelephoneWillsEnabledValue = (
  partnerSetting: boolean | null,
  campaignSetting: TelephoneWillsEnabledCampaignOverride
): boolean => {
  if (campaignSetting == null) {
    // Note: Even though telephoneWillsEnabled on the Partner model is a boolean
    // it's not marked as required and has no default value so we need to handle
    // the null case here.
    if (partnerSetting == null) {
      return true
    }
    return partnerSetting
  }

  return campaignSetting === PARTNER_CAMPAIGN_PAGE_TELEPHONE_WILLS_ENABLED_VALUE
}
