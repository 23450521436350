import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Wrapper, P, Image } from '@farewill/ui'
import { GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import TermsModal from './TermsModal'

const StyledBannerWrapper = styled.section`
  ${({ background }) => `
    background: ${background};

    // add border when the background is white
    ${
      background.match(/^#([Ff]{3}){1,2}$/) &&
      `
      border-bottom: 1px solid ${COLOR.GREY.LIGHT};
    `
    }
  `}
`

const StyledBanner = styled(Wrapper)`
  ${screenMin.m`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 120px;
  `}
`

const StyledLogo = styled(Image)`
  ${screenMin.m`
    margin: 0 ${GTR.L} 0 0;

    ${({ $rightAlign }) =>
      $rightAlign &&
      `
      margin: 0 0 0 ${GTR.L};
      order: 1;
    `}
  `}

  ${screenMin.l`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ $rightAlign }) => ($rightAlign ? 'right' : 'left')}: 0;
    margin: 0 ${GTR.XL};
    max-height: 85%;
    width: auto;
  `}
`

const StyledText = styled(P)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  ${({ color }) => `color: ${color};`}

  ${screenMin.m`
    flex: 1;
  `}

  ${screenMin.l`
    text-align: center;
    max-width: 400px;
  `};
`

const StyledTermsButton = styled.button`
  text-decoration: underline;
`

const CoBrandedBanner = ({
  text,
  textColour,
  backgroundColour,
  logo,
  rightAlignLogo,
  termsAndConditions,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <StyledBannerWrapper background={backgroundColour}>
        <StyledBanner
          container
          containerPaddingTop="S"
          containerPaddingBottom="S"
        >
          {logo?.file && (
            <StyledLogo
              path={logo.file.url}
              formatPath={({ path, width }) =>
                `${path}?w=${width}&h=${width * 0.7}&fit=pad&f=${
                  rightAlignLogo ? 'right' : 'center'
                }&bg=${backgroundColour}`
              }
              alt={logo.title}
              width={150}
              widthFromL={200}
              stretch
              $rightAlign={rightAlignLogo}
            />
          )}

          <StyledText
            color={textColour}
            margin={['M', 0, 'XS']}
            marginFromM={0}
          >
            <ReactMarkdown
              components={{
                // eslint-disable-next-line react/display-name, react/prop-types
                p: ({ children }) => <span>{children}</span>,
              }}
              escapeHtml
            >
              {text}
            </ReactMarkdown>
            {termsAndConditions && (
              <>
                {' '}
                <StyledTermsButton
                  type="button"
                  onClick={() => setModalIsOpen(true)}
                >
                  T&Cs apply
                </StyledTermsButton>
                .
              </>
            )}
          </StyledText>
        </StyledBanner>
      </StyledBannerWrapper>

      {modalIsOpen && (
        <TermsModal
          close={() => setModalIsOpen(false)}
          terms={termsAndConditions}
        />
      )}
    </>
  )
}

CoBrandedBanner.propTypes = {
  text: PropTypes.string.isRequired,
  textColour: PropTypes.string.isRequired,
  backgroundColour: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  rightAlignLogo: PropTypes.bool,
  termsAndConditions: PropTypes.string,
}

CoBrandedBanner.defaultProps = {
  rightAlignLogo: false,
  termsAndConditions: '',
  logo: null,
}

export default CoBrandedBanner
