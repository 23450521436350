import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, ArrowLeftIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import Modal from '.'

const StyledModalContent = styled(Wrapper)`
  color: ${COLOR.GREY.DARK};
  background: ${COLOR.WHITE};
  text-align: left;
  word-break: break-word;
  overflow: auto;
  height: calc(100vh - 120px);

  && {
    margin-top: 120px;
  }

  ${screenMin.m`
    height: 100vh;
    margin-left: 60px;

    && {
      margin-top: 0;
    }
  `}

  ${screenMin.l`
    margin-left: calc(100% - 910px);
  `}
`

const StyledArrowIcon = styled(ArrowLeftIcon)`
  margin-right: ${GTR.XXS};
`

const SideModal = ({ close, children }) => (
  <Modal close={close}>
    <StyledModalContent
      padding="M"
      paddingFromM="XL"
      paddingFromL={['XL', 'XXL']}
    >
      <Wrapper margin={[0, 0, 'M']} marginFromL={[0, 0, 'L']}>
        <button type="button" onClick={close}>
          <StyledArrowIcon size="S" inline /> Back
        </button>
      </Wrapper>

      {children}
    </StyledModalContent>
  </Modal>
)

SideModal.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default SideModal
