import React from 'react'
import { graphql } from 'gatsby'

import PATHS from 'paths'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import PartnerLandingPageLayout from 'layouts/PartnerLandingPageLayout'

import { useRecordGAPageview } from 'lib/hooks/useRecordGAPageview'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'
import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import {
  getTelephoneWillsEnabledValue,
  TelephoneWillsEnabledCampaignOverride,
} from 'lib/contentful/partnerCampaignPages'

import Overview from '../../components/Overview'

interface PartnerOnlineProps {
  data: {
    page: {
      utmCampaign: string
      utmMedium: string
      bannerText: { bannerText: string }
      telephoneWillsEnabled: TelephoneWillsEnabledCampaignOverride
      partner: {
        bannerBackgroundColor: string
        bannerLogoAlignment: 'left' | 'right'
        bannerText: { bannerText: string }
        bannerTextColor: string
        logo: { file: { url: string }; title: string }
        utmSource: string
        utmChannel: string
        telephoneWillsEnabled: boolean
        hideFarewillLogoInHeader: boolean | null
        partnerId: string
      }
      termsAndConditions: { termsAndConditions: string } | null
      heroTitle: string | null
      heroSubtitle: { heroSubtitle: string } | null
      heroCtaText: string | null
    }
  }
}

const PartnerOnline = ({
  data: {
    page: {
      utmCampaign,
      utmMedium,
      bannerText,
      telephoneWillsEnabled,
      partner,
      termsAndConditions,
      heroTitle,
      heroSubtitle,
      heroCtaText,
    },
  },
}: PartnerOnlineProps): React.ReactElement => {
  const partnerWithCampaignValues = {
    ...partner,
    telephoneWillsEnabled: getTelephoneWillsEnabledValue(
      partner.telephoneWillsEnabled,
      telephoneWillsEnabled
    ),
  }
  useRecordGAPageview('PartnerLandingPageView', {
    partner: partner.utmSource,
  })
  useRecordSplitPageview('wills_landing_page_view', undefined, {
    source: 'partner',
  })
  return (
    <PartnerLandingPageLayout
      bannerText={bannerText?.bannerText}
      title="Online Will Writing Service | Legal Will In 15 Minutes"
      description={`Our solicitor-approved guide helps you write a will online from the comfort of your own home. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot. National Will Writing Firm of the Year.`}
      product={PRODUCTS.WILLS}
      canonicalPath={PATHS.WILLS.OVERVIEW}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      utmCampaign={utmCampaign}
      utmMedium={utmMedium}
      partner={partnerWithCampaignValues}
      termsAndConditions={termsAndConditions}
    >
      <Overview
        heroContent={{
          title: heroTitle,
          subtitle: heroSubtitle?.heroSubtitle,
          ctaText: heroCtaText,
        }}
        isPartnerCampaignPage
        telephoneWillsEnabled={partnerWithCampaignValues?.telephoneWillsEnabled}
        hidePricing
      />
    </PartnerLandingPageLayout>
  )
}

export const query = graphql`
  query PartnerOnline($slug: String!) {
    page: contentfulPartnerCampaignPage(slug: { eq: $slug }) {
      ...CoBrandedBannerFields
      utmCampaign
      utmMedium
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      heroCtaText
      telephoneWillsEnabled
      partner {
        utmSource
        utmChannel
        telephoneWillsEnabled
        hideFarewillLogoInHeader
        partnerId
      }
    }
  }
`

export default PartnerOnline
