import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Wrapper, H } from '@farewill/ui'

import SideModal from 'components/Modal/SideModal'
import { StyledRichContentWrapper } from 'views/general/templates/components/RichContent'

const TermsModal = ({ close, terms }) => (
  <SideModal close={close}>
    <H tag="h2" size="XL" decorative>
      Terms and Conditions
    </H>

    <Wrapper tag={StyledRichContentWrapper}>
      <ReactMarkdown escapeHtml>{terms}</ReactMarkdown>
    </Wrapper>
  </SideModal>
)

TermsModal.propTypes = {
  close: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
}

export default TermsModal
