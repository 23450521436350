import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BulletSeparator, H, P, Wrapper } from '@farewill/ui'

import PATHS from 'paths'
import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
  ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import ProductPricing from 'components/ProductPricing'

const individualPricingNote = (
  <span>
    or{' '}
    <Link to={PATHS.WILLS.FOR_COUPLES}>
      {formatPriceInPounds(ONLINE_WILL_COUPLES_PRICE_IN_POUNDS)} for couples
    </Link>
  </span>
)

const couplesPricingNote = (
  <>
    when you
    <br />
    pay together
  </>
)

const Pricing = ({ couples }) => (
  <Wrapper maxWidth={800} margin={[0, 'auto']}>
    <ProductPricing
      price={
        couples
          ? ONLINE_WILL_COUPLES_PRICE_IN_POUNDS
          : ONLINE_WILL_PRICE_IN_POUNDS
      }
      priceNote={couples ? couplesPricingNote : individualPricingNote}
    >
      <H size="XS" margin={[0, 0, 'XS']}>
        <BulletSeparator>
          <span>Update Service</span>
          <span>
            {formatPriceInPounds(ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS)} a
            year (optional)
          </span>
        </BulletSeparator>
      </H>
      <P>
        For just {formatPriceInPounds(ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS)}{' '}
        a year, update your will whenever your circumstances change and our
        specialists will check it for you.
      </P>
    </ProductPricing>
  </Wrapper>
)

Pricing.propTypes = {
  couples: PropTypes.bool,
}

Pricing.defaultProps = {
  couples: false,
}

export default Pricing
